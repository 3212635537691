<template>
  <div
    :class="{
      active:
        $route.name === route.name ||
        additionalActiveRoutes.includes($route.name),
    }"
    class="side-nav-item fw-medium"
    @click="
      () => {
        $emit('redirect');
        $router.push(route);
      }
    "
  >
    <i :class="iconClass" class="bi text-secondary"></i>
    <div class="side-nav-item-text">{{ text }}</div>
  </div>

  <div
    v-if="
      $route.name === route.name || additionalActiveRoutes.includes($route.name)
    "
  >
    <slot name="active-content"></slot>
  </div>
</template>

<script lang="ts">
import { RouteLocationRaw } from 'vue-router';

export default {
  name: 'NavItem',
  emits: ['redirect'],
  props: {
    iconClass: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    route: {
      type: Object as () => RouteLocationRaw,
      default: () => ({}),
    },
    additionalActiveRoutes: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  & .side-nav-item-text {
    color: var(--primary) !important;
  }

  & i {
    color: var(--primary) !important;
  }
}
</style>
