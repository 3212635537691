<script lang="ts" setup>
import { useFolderStore, SelectedFolder } from '@/stores/folder.store';
import NavBar from '@/components/ui/nav/NavBar.vue';
import NavItem from '@/components/ui/nav/NavItem.vue';
import TopNav from '@/components/ui/nav/TopNav.vue';
import { useAuthStore } from '@/stores/auth.store';
import FolderManager from '@/components/mutable/nav/FolderManager.vue';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UploadManager from '@/components/mutable/nav/UploadManager.vue';
import { useLoaderStore } from '@/stores/loader.store';

const router = useRouter();

const logout = () => {
  useAuthStore().logout();
};

const title = computed(() => {
  switch (useRoute().name) {
    case 'SignDocument':
      return 'Digitálny podpis';
    case 'FastSign':
      return 'Rýchly podpis';
    case 'ShowSignedDocument':
      return 'Podpísaný dokument';
    case 'SignWithToken':
      return 'Podpíšte dokument';
    case 'Documents':
      return 'Moje dokumenty';
    case 'Templates':
      return 'Moje iŠablóny';
    default:
      return '';
  }
});

const folderColors = {
  [SelectedFolder.CUSTOM]: '#063970',
  [SelectedFolder.ALL]: '#063970',
  [SelectedFolder.WITHOUT_FOLDER]: '#063970',
  [SelectedFolder.TRASH]: '#063970',
};
</script>

<template>
  <loading-overlay v-if="useLoaderStore().loading"></loading-overlay>

  <div
    class="full-height-child full-height d-flex gap-large flex-column overflow-hidden"
  >
    <top-nav @logout="logout"></top-nav>

    <div class="p-3">
      <div
        ref="routerWrapper"
        class="d-flex p-0 h-100 w-100 mw-default mx-auto gap-large"
      >
        <nav-bar
          v-if="
            ![
              'SignWithToken',
              'SignDocument',
              'ShowSignedDocument',
              'SignSuccess',
              'SignError',
            ].includes($route.name)
          "
        >
          <nav-item
            icon-class="bi-file-earmark-richtext-fill"
            :route="{
              name: 'Documents',
              query: { select: 'all' },
            }"
            text="iPodpis"
          >
            <template #active-content>
              <div class="p-3 border-bottom">
                <upload-manager></upload-manager>
                <folder-manager></folder-manager>
              </div>
            </template>
          </nav-item>
          <nav-item
            icon-class="bi-rulers"
            :route="{
              name: 'Templates',
            }"
            text="iŠablóny"
          >
            <template #active-content>
              <div class="p-3 border-bottom">
                <upload-manager></upload-manager>
                <folder-manager></folder-manager>
              </div>
            </template>
          </nav-item>

          <nav-item
            icon-class="bi-pencil-fill"
            :route="{
              name: 'FastSign',
            }"
            text="Rýchly podpis"
          >
          </nav-item>

          <nav-item
            icon-class="bi-door-open-fill"
            :route="{
              name: 'Login',
            }"
            text="Odhlásiť"
            @redirect="logout"
          >
          </nav-item>
        </nav-bar>

        <div class="mx-auto flex-fill">
          <div
            class="mb-3 badge align-items-center w-fit-content d-flex"
            :style="`background-color: ${
              folderColors[useFolderStore().selectedFolder]
            }`"
            v-if="useRoute().name === 'Documents'"
          >
            <i class="bi bi-folder-fill me-1"></i>
            <div class="fw-medium text-white">
              {{
                useFolderStore().selectedFolder === SelectedFolder.CUSTOM
                  ? useFolderStore().folder?.name
                  : useFolderStore().selectedFolder
              }}
            </div>
          </div>
          <div class="text-left">
            <portal-target name="above-header"></portal-target>
          </div>
          <div class="d-flex mb-4 justify-content-between">
            <div>
              <h2
                class="text-left text-secondary font-weight-black app-title"
                v-if="title"
              >
                {{ title }}
              </h2>
              <portal-target v-else name="subpage-header"></portal-target>
            </div>

            <div>
              <portal-target name="right-flex-header"></portal-target>
            </div>
          </div>

          <router-view v-slot="{ Component }">
            <suspense>
              <template #default>
                <component :is="Component" />
              </template>

              <template #fallback>
                <div class="d-flex justify-content-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </template>
            </suspense>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>
