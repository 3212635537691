import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAM4e_9qthhu_GfUhIqhkLMfxyQuDNSqXk',
  authDomain: 'data-gather-605a4.firebaseapp.com',
  projectId: 'data-gather-605a4',
  storageBucket: 'data-gather-605a4.appspot.com',
  messagingSenderId: '1013180059464',
  appId: '1:1013180059464:web:817b733e49929c0c252d7d',
  measurementId: 'G-KD8GRH0FWQ',
};

export default initializeApp(firebaseConfig);
