import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import {
  closeModal,
  getCurrentModal,
  useModalRouter,
  config,
} from 'jenesius-vue-modal';
import { useAuthStore } from '@/stores/auth.store';
import AppView from '../parents/AppParent.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'AppView',
    component: AppView,

    children: [
      {
        name: 'Documents',
        path: 'ipodpis',
        component: () => import('@/pages/lists/DocumentList.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'DocumentEditor',
        path: 'dokument/e/:documentId',
        component: () => import('@/parents/DocumentEditorParent.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'Templates',
        path: 'sablony',
        component: () => import('@/pages/lists/DocumentTemplatesList.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'FastSign',
        path: 'podpis',
        component: () => import('@/pages/sign/Signature.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'SignDocument',
        path: 'podpis/d/:documentId?',
        component: () => import('@/pages/sign/Signature.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'ShowSignedDocument',
        path: 'podpis/s/:signatureSetId/d/:documentId',
        component: () => import('@/pages/sign/Signature.vue'),
        meta: {
          requiresAuth: true,
        },
      },

      {
        name: 'SignWithToken',
        path: 'podpis/t/:authToken',
        component: () => import('@/pages/sign/Signature.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: 'SignSuccess',
        path: 'podpis-hotovo',
        component: () => import('@/pages/sign/SignSuccess.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: 'SignError',
        path: 'podpis-chyba',
        component: () => import('@/pages/sign/SignError.vue'),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },

  {
    path: '/auth',
    name: 'AuthView',
    component: () => import('@/parents/AuthParent.vue'),

    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('../pages/auth/AuthLogin.vue'),
      },
    ],
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => ({}),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

config({
  backgroundClose: false,
});
useModalRouter.init(router);

//Len kôli modal systému, aby fungoval správne s routerom
router.beforeEach(async (to, from, next) => {
  if (getCurrentModal()) {
    try {
      await closeModal();
      next();
    } catch (e) {
      next(false);
    }
  } else next();
});

//Hlavný router guard, ktorý zabezpečí, že sa načítajú všetky potrebné údaje predtým, ako sa presmeruje na danú stránku
router.beforeEach(async (to, from, next) => {
  if (to.name === 'AppView') {
    //Redirect na Documents
    next({ name: 'Documents' });
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !useAuthStore().user
  ) {
    //Ak nie je inicializovaný, tak ho inicializujeme
    if (!useAuthStore().onAuthHookInitialized)
      await useAuthStore().initialize();

    if (!useAuthStore().user) next({ name: 'Login' });
    else next();
  }
  //Ak sa nedopytuje na stránku, ktorá vyžaduje prihlásenie, tak ho pustíme
  else next();
});

export default router;
