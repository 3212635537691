<script lang="ts" setup>
import { defineEmits, defineProps } from 'vue';

defineProps({
  smallDesign: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: File,
    default: null,
  },
  accept: {
    type: String,
    default:
      '.pdf, .docx, .doc, .xlsx, .pptx, .ppt, .xls, .txt, .rtf, .odt, .ods, .odp, .odg, .odf, .odc, .odb, .odm, .ott, .ots, .otp, .otg, .otf, .oti, .otc, .otm, .oth',
  },
});

const emit = defineEmits(['update:modelValue']);

function handleDrop(e: DragEvent) {
  if (e.dataTransfer?.files) {
    handleFiles(e.dataTransfer.files);
  }
}

function handleFiles(files: FileList) {
  if (!files.length) return;
  const file = files[0];
  emit('update:modelValue', file);
}
</script>

<template>
  <div class="bg-white p-3">
    <input
      id="input-file"
      :accept="accept"
      class="visually-hidden"
      type="file"
      @change="handleFiles($event.target.files)"
    />

    <label
      v-if="!smallDesign"
      class="upload-file"
      for="input-file"
      @dragover.prevent
      @drop.prevent="handleDrop"
    >
      <span class="content absolute-center w-100">
        <i class="bi bi-upload" style="font-size: 5rem"></i> <br />
        <span class="text-secondary" style="font-size: 1.7rem">
          <span class="fw-bold">Vyberte dokument</span>,<br />
          alebo ho sem potiahnite.
          <br />
          <small v-if="accept !== '.docx'"
            >Podporujeme .doc, .docx, .pdf, .pptx, .xlsx</small
          >
        </span>
        <br />
        <span>
          Rýchly podpis sa odohráva čiste u Vás - neukladáme žiadne dáta na
          server.
        </span>
      </span>
    </label>

    <label
      v-else
      class="p-3 text-center border-dashed text-secondary radius-default w-100 position-relative"
      for="input-file"
      @dragover.prevent
      @drop.prevent="handleDrop"
    >
      <span v-if="!modelValue">
        <i class="bi bi-upload" style="font-size: 3rem"></i> <br />
        <slot name="desc">
          <span class="fw-bold"
            >Vyberte <span v-if="accept === '.docx'">DOCX</span> dokument</span
          >,<br />
          alebo ho sem potiahnite.
          <br />
          <small v-if="accept !== '.docx'"
            >Podporujeme .doc, .docx, .pdf, .pptx, .xlsx</small
          >
        </slot>
      </span>
      <span v-else>
        <i
          class="bi bi-x-circle-fill position-absolute"
          style="top: 0; right: 0; transform: translate(50%, -50%)"
          @click="emit('update:modelValue', null)"
        ></i>

        <i class="bi bi-file-earmark-text-fill" style="font-size: 3rem"></i>
        <br />
        <span class="fw-bold">{{ modelValue.name }}</span>
      </span>
    </label>
  </div>
</template>

<style lang="scss">
@import '../../scss/global';

.upload-file {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 400px;
  border: dashed 2px $gray-500;
  color: $gray-500;
}
</style>
