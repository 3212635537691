import { defineStore } from 'pinia';
import { Document } from '@/types/models.types';
import {
  createBlankDocument,
  deleteDocument,
  duplicateDocument,
  listDocuments,
  moveDocument,
  postDocument,
  renameDocument,
  retrieveDocument,
} from '@/services/document.service';
import { SelectedFolder, useFolderStore } from '@/stores/folder.store';
import { ConvertTo, CreateDocumentPayload } from '@/types/payloads.types';

export const useDocumentStore = defineStore('document', {
  state: () => ({
    documents: [] as Document[],
    document: null as Document | null,
    initialized: false,
    hasNextPage: false,
  }),
  actions: {
    async fetchDocuments(
      selectedFolder: SelectedFolder,
      folderId: string | null = null,
      page = 1,
      signStatus: 'all' | 'not_signed' | 'in_signing_process' | 'signed' = 'all'
    ) {
      if (page > 1) {
        const { data, hasNextPage } = await listDocuments(
          selectedFolder,
          folderId,
          page,
          signStatus
        );
        this.hasNextPage = hasNextPage;

        this.documents.push(...data);
        return this.documents;
      }

      const { data, hasNextPage } = await listDocuments(
        selectedFolder,
        folderId,
        page,
        signStatus
      );
      this.documents = data;
      this.hasNextPage = hasNextPage;
      this.initialized = true;
      return this.documents;
    },
    async fetchDocument(id: string) {
      this.document = await retrieveDocument(id);
      return this.document;
    },

    async create(payload: CreateDocumentPayload) {
      const document = await postDocument(payload);

      if (payload.folderId) {
        useFolderStore().manageItemsInFolder(payload.folderId, document.id);
      }

      this.documents.push(document);
      return document;
    },

    async moveToFolder(documentId: string, folderId?: string) {
      const document = this.documents.find(
        (document) => document.id === documentId
      );
      if (!document) {
        return;
      }

      const oldFolderId = document.folderId;

      //Ak momentálne pozeráme custom zložku a dokument presúvame do inej zložky, alebo
      //ak momentálne pozeráme dokumenty bez zložky a dokument nadobúda zložku,
      //tak ho odstránime z aktuálneho zoznamu, kt. zobrazujeme
      if (
        useFolderStore().selectedFolder === SelectedFolder.CUSTOM ||
        (useFolderStore().selectedFolder === SelectedFolder.WITHOUT_FOLDER &&
          !oldFolderId)
      ) {
        this.documents = this.documents.filter(
          (document) => document.id !== documentId
        );
      }

      if (oldFolderId) {
        useFolderStore().manageItemsInFolder(oldFolderId, documentId, 'remove');
      }

      if (folderId) {
        useFolderStore().manageItemsInFolder(folderId, documentId);
      }

      document.folderId = folderId;
      return moveDocument(documentId, folderId);
    },

    async removeDocument(id: string) {
      await deleteDocument(id);

      const document = this.documents.find((document) => document.id === id);
      if (!document) {
        return;
      }

      const folderId = document.folderId;
      if (folderId) {
        useFolderStore().manageItemsInFolder(folderId, id, 'remove');
      }

      this.documents = this.documents.filter((document) => document.id !== id);
      this.document = null;
    },

    async duplicateDocument(documentId: string) {
      const document = await duplicateDocument(documentId);
      this.documents.push(document);
      return document;
    },

    async renameDocument(documentId: string, name: string) {
      let oldName: string | undefined;

      this.documents = this.documents.map((_document) => {
        if (_document.id === documentId) {
          oldName = _document.name;
          return { ..._document, name };
        }
        return _document;
      });
      return await renameDocument(documentId, name).catch((e) => {
        this.documents = this.documents.map((_document) =>
          _document.id === documentId
            ? { ..._document, name: oldName }
            : _document
        );

        return Promise.reject(e);
      });
    },

    removeDocumentsFromStore(ids: string[]) {
      this.documents = this.documents.filter(
        (document) => !ids.includes(document.id)
      );
    },

    async createBlankDocument(format: ConvertTo, folderId?: string) {
      const doc = await createBlankDocument({ format, folderId });

      if (folderId) {
        useFolderStore().manageItemsInFolder(folderId, doc.id);
      }

      this.documents.push(doc);

      return doc;
    },
  },
});
