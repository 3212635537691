import axios, { AxiosError, AxiosResponse } from 'axios';
import router from '@/router';
import app from '@/firebaseApp';
import { getAuth } from 'firebase/auth';
const auth = getAuth(app);

const base =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost'
    : 'https://api.idocuments.sk';

// const base = 'https://api.idocuments.sk';

const axiosInstance = axios.create({
  baseURL: `${base}/services/realestate/api/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const dAxios = axios.create({
  baseURL: `${base}/services/business/api/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const successCallback = (response: AxiosResponse) => response;
const errCallback = async (error: AxiosError) => {
  //401 príde, ak nemáme žiaden token - vyžaduje sa login
  if (error.response?.status === 401) {
    return router.push({ name: 'Login' });
  }
  console.log(error.response?.status, error.response?.data);

  //403 väčšinou chodí, keď nemáme aktuálny token, takže ho refreshneme
  if (error.response?.status === 403 && error.config && auth.currentUser) {
    const token = await auth.currentUser.getIdToken(true);
    dAxios.defaults.headers.common.Authorization = `${token}`;
    axiosInstance.defaults.headers.common.Authorization = `${token}`;

    axios
      .request(error.config)
      .then((res) => res)
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(successCallback, errCallback);
dAxios.interceptors.response.use(successCallback, errCallback);
export default axiosInstance;
