import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import { Collapse } from 'vue-collapsed';
import axiosInstance from '@/axios/axios.config';
import Toast, { useToast } from 'vue-toastification';
import Multiselect from 'vue-multiselect';
import { AxiosInstance } from 'axios';
import { vMaska } from 'maska';
import PortalVue from 'portal-vue';
import Emittery from 'emittery';
import FloatingVue from 'floating-vue';
Emittery.isDebugEnabled = false;
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
import Vue3Lottie from 'vue3-lottie';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'vue-toastification/dist/index.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import 'floating-vue/dist/style.css';
import 'bootstrap';

const app = createApp(App);

const TOAST_CONFIG = {
  position: 'top-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
};

app
  .use(createPinia())
  .use(router)
  .use(Toast, TOAST_CONFIG)
  .use(PortalVue)
  .use(Vue3Lottie, { name: 'LottieAnimation' })
  .use(FloatingVue)

  .component('loading-overlay', LoadingOverlay)
  .component('collapse', Collapse)
  .component('multiselect', Multiselect);

//Globalne premenne pre options api
app.config.globalProperties.$axios = axiosInstance as AxiosInstance;
app.config.globalProperties.$toast = useToast();
//Injections
app.provide('axios', axiosInstance);
app.provide('toast', useToast());
app.provide('emitter', new Emittery());

app.directive('maska', vMaska);

app.mount('#app');
