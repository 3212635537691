<template>
  <router-view />
  <widget-container-modal />
</template>

<script>
import { container } from 'jenesius-vue-modal';

export default {
  name: 'App',
  components: {
    WidgetContainerModal: container,
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
