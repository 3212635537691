import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6419c18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "side-nav-item-text" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([{
      active:
        _ctx.$route.name === $props.route.name ||
        $props.additionalActiveRoutes.includes(_ctx.$route.name),
    }, "side-nav-item fw-medium"]),
      onClick: _cache[0] || (_cache[0] = 
      () => {
        _ctx.$emit('redirect');
        _ctx.$router.push($props.route);
      }
    )
    }, [
      _createElementVNode("i", {
        class: _normalizeClass([$props.iconClass, "bi text-secondary"])
      }, null, 2),
      _createElementVNode("div", _hoisted_1, _toDisplayString($props.text), 1)
    ], 2),
    (
      _ctx.$route.name === $props.route.name || $props.additionalActiveRoutes.includes(_ctx.$route.name)
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "active-content", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}