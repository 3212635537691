import { dAxios } from '@/axios/axios.config'
import {
  CreateFolderPayload,
  UpdateFolderPayload,
} from '@/types/payloads.types'

export const listFolders = (fetchDocumentFolders = true) => {
  let q = '?where='
  q += fetchDocumentFolders ? 'isDocumentFolder:true' : 'isTemplateFolder:true'

  return dAxios.get(`/folder${q}`).then((res) => res.data.data)
}

export const retrieveFolder = (id: string) => {
  return dAxios.get(`/folder/${id}`).then((res) => res.data.data)
}

export const postFolder = ({ name, isDocumentFolder }: CreateFolderPayload) => {
  return dAxios
    .post('/folder', {
      name,
      isDocumentFolder,
      isTemplateFolder: !isDocumentFolder,
    })
    .then((res) => res.data.data)
}

export const patchFolder = (id: string, data: UpdateFolderPayload) => {
  return dAxios.patch(`/folder/${id}`, data).then((res) => res.data.data)
}

export const deleteFolder = (id: string) => {
  return dAxios.delete(`/folder/${id}`).then((res) => res.data.data)
}
