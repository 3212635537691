import { defineStore } from 'pinia';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  User,
} from 'firebase/auth';
import app from '@/firebaseApp';
import axios, { dAxios } from '@/axios/axios.config';
import { AuthUser } from '@/types/models.types';

const auth = getAuth(app);

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      user: null as AuthUser | null,
      onAuthHookInitialized: false,
    };
  },
  actions: {
    //metoda vrati cestu, na ktoru sa ma presmerovat, alebo nevrati nič a presmeruje sa na next()
    //Používať iba v router guardoch, aby sme zabezpečili, že sa načítajú všetky potrebné údaje predtým, ako sa presmeruje na danú stránku
    async initialize(): Promise<void> {
      if (!this.onAuthHookInitialized) {
        await new Promise((resolve) => {
          const unsubscribe = onAuthStateChanged(
            auth,
            async (user: User | null) => {
              this.onAuthHookInitialized = true;

              if (user) {
                unsubscribe();
                const token = await user.getIdToken(); // Získa ID token
                //Potrebujeme nastaviť axiosu token, aby sme mohli robiť requesty na BE
                axios.defaults.headers.common.Authorization = `${token}`;
                dAxios.defaults.headers.common.Authorization = `${token}`;

                const authUser = { ...user, token } as AuthUser;
                this.user = authUser;

                resolve(authUser);
              } else {
                this.user = null;
                resolve(null);
              }
            }
          );
        });
      }
    },

    login(email: string, password: string) {
      return signInWithEmailAndPassword(auth, email, password);
    },

    async logout() {
      try {
        await signOut(auth);

        this.user = null;
        this.onAuthHookInitialized = false;
      } catch (e) {
        console.error(e);
      }
    },
  },
});
