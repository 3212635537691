<script lang="ts" setup>
import { Vue3Lottie as LottieAnimation } from 'vue3-lottie';
import loadingAnimation from '@/assets/lottie/loading.json';
import signAnimation from '@/assets/lottie/sign.json';
import Logo from '@/components/ui/Logo.vue';
import { onBeforeUnmount } from 'vue';
import { useLoaderStore } from '@/stores/loader.store';

const animation =
  useLoaderStore().loadingAnimation === 'loading.json'
    ? loadingAnimation
    : signAnimation;

onBeforeUnmount(() => {
  useLoaderStore().resetToDefault();
});
</script>

<template>
  <div class="overlay">
    <div class="text-center">
      <logo class="mb-5" centered large></logo>
      <lottie-animation
        :animation-data="animation"
        :width="useLoaderStore().animationWidth"
      ></lottie-animation>
      <div v-if="useLoaderStore().loaderMsg">
        <h4 class="mt-5">{{ useLoaderStore().loaderMsg }}</h4>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
