import { dAxios } from '@/axios/axios.config';
import { Document } from '@/types/models.types';
import { SelectedFolder } from '@/stores/folder.store';
import {
  CreateBlankDocumentPayload,
  CreateDocumentPayload,
  UpdateDocumentPayload,
} from '@/types/payloads.types';
import { appendWhere } from '@/utils/services.utils';

export function listDocuments(
  selectedFolder: SelectedFolder,
  folderId: string | null = null,
  page = 1,
  signStatus: 'all' | 'not_signed' | 'in_signing_process' | 'signed' = 'all'
): Promise<{ data: Document[]; hasNextPage: boolean }> {
  //Ak neposkytneme id spolu so selectedFolder === CUSTOM, tak sa zmeni na ALL
  if (selectedFolder === SelectedFolder.CUSTOM && !folderId)
    selectedFolder = SelectedFolder.ALL;

  let q = '';
  if (selectedFolder !== SelectedFolder.ALL)
    q = `?where=folderId:${
      selectedFolder === SelectedFolder.WITHOUT_FOLDER ? null : folderId
    }`;

  if (signStatus !== 'all') {
    if (signStatus === 'not_signed')
      q = appendWhere(q, 'signatures', JSON.stringify({ none: {} }));
    else if (signStatus === 'signed')
      q = appendWhere(q, 'signatures', JSON.stringify({ some: {} }));
  }

  if (page > 1) q.length ? (q += `&page=${page}`) : (q = `?page=${page}`);

  return dAxios.get(`/document${q}`).then((res) => ({
    data: res.data.data || [],
    hasNextPage: res.data.hasNextPage,
  }));
}

export function retrieveDocument(id: string): Promise<Document> {
  return dAxios.get(`/document/${id}`).then((res) => res.data.data);
}

export function postDocument({
  file,
  folderId,
}: CreateDocumentPayload): Promise<Document> {
  const formData = new FormData();
  formData.append('file', file);
  if (folderId) {
    formData.append('folder__id', folderId);
  }

  return dAxios
    .post('/document', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data.data);
}

export function patchDocument(
  id: string,
  data: UpdateDocumentPayload
): Promise<Document> {
  return dAxios.patch(`/document/${id}`, data).then((res) => res.data.data);
}

export function deleteDocument(id: string): Promise<Document> {
  return dAxios.delete(`/document/${id}`).then((res) => res.data.data);
}

export function moveDocument(
  documentId: string,
  newFolderId?: string
): Promise<Document> {
  const payload = {} as {
    folder__id?: string;
  };

  if (newFolderId) {
    payload['folder__id'] = newFolderId;
  }

  return dAxios
    .post(`/document/${documentId}/move`, payload)
    .then((res) => res.data.data);
}

export function duplicateDocument(id: string): Promise<Document> {
  return dAxios.post(`/document/${id}/duplicate`).then((res) => res.data.data);
}

export function renameDocument(id: string, name: string): Promise<Document> {
  return dAxios
    .patch(`/document/${id}/rename`, { name })
    .then((res) => res.data.data);
}

export function createBlankDocument(
  payload: CreateBlankDocumentPayload
): Promise<Document> {
  return dAxios
    .post('/document/create-blank', payload)
    .then((res) => res.data.data);
}
