<script lang="ts" setup>
import { inject, ref, watch } from 'vue';
import Emittery from 'emittery';
import { useRouter } from 'vue-router';

const sideNav = ref(null as HTMLDivElement | null);
const sideToggled = ref(false);

const emitter = inject('emitter') as Emittery;
emitter.on('toggleSideNav', () => {
  if (!sideNav.value) return;

  sideToggled.value = !sideToggled.value;

  const topNavHeight = document.querySelector('.idocs-nav')?.clientHeight || 0;

  sideNav.value.classList.toggle('d-none');
  sideNav.value.classList.toggle('w-100');
  sideNav.value.classList.toggle('h-100');
  sideNav.value.classList.toggle('position-fixed');
  sideNav.value.classList.toggle('radius-default');
  sideNav.value.classList.toggle('left-0');
  sideNav.value.classList.toggle('z-index-top');

  if (sideNav.value!.style.top) sideNav.value!.style.top = '';
  else sideNav.value!.style.top = `${topNavHeight}px`;
});

watch(useRouter().currentRoute, () => {
  if (sideToggled.value) emitter.emit('toggleSideNav');
});
</script>

<template>
  <div
    ref="sideNav"
    class="h-fit-content radius-default side-nav text-left position-relative flex-shrink-0 d-none d-lg-block"
  >
    <slot></slot>
  </div>
</template>

<style lang="scss">
@import '../../../scss/global';

.rv {
  @media (max-width: 1400px) {
    padding: 1rem;
  }
}

.burger {
  border-radius: 10px;

  &.active {
    background-color: $gray-200;
  }
}

.idocs-nav {
  background: #fff;
  z-index: 1;
  border-bottom: 1px solid $gray-200;
}

.side-nav {
  background: #fff;
  width: 300px;
  z-index: 0;
}

.mw-default {
  max-width: 1400px;
}

.side-nav-item {
  display: flex;
  gap: 10px;
  padding: 1rem;
  user-select: none;

  &:first-child {
    border-top-left-radius: $radius-default;
    border-top-right-radius: $radius-default;
  }

  &:last-child {
    border-bottom-left-radius: $radius-default;
    border-bottom-right-radius: $radius-default;
  }

  &:not(.active):not(.disabled) {
    cursor: pointer;

    &:hover {
      background: rgba($gray-400, 0.1);
    }
  }

  &.active {
    color: $primary;
    background: rgba($primary, 0.1);
  }
}

.expanded {
  border-bottom: 1px solid $gray-200;
}

label {
  cursor: pointer;
}

.router-wrapper {
  overflow: auto;
}
</style>
