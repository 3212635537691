import { dAxios } from '@/axios/axios.config';
import { Document, DocumentTemplate } from '@/types/models.types';
import {
  CreateBlankTemplatePayload,
  CreateTemplatePayload,
} from '@/types/payloads.types';
import { SelectedFolder } from '@/stores/folder.store';

export function postTemplate(
  payload: CreateTemplatePayload
): Promise<DocumentTemplate> {
  const formData = new FormData();
  formData.append('file', payload.file);
  if (payload.folderId) {
    formData.append('folder__id', payload.folderId);
  }

  return dAxios
    .post('/document-template', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data.data);
}

export function listTemplates(
  selectedFolder: SelectedFolder,
  folderId: string | null = null,
  page = 1
): Promise<{ data: DocumentTemplate[]; hasNextPage: boolean }> {
  //Ak neposkytneme id spolu so selectedFolder === CUSTOM, tak sa zmeni na ALL
  if (selectedFolder === SelectedFolder.CUSTOM && !folderId)
    selectedFolder = SelectedFolder.ALL;

  let q = '';
  if (selectedFolder !== SelectedFolder.ALL)
    q = `?where=folderId:${
      selectedFolder === SelectedFolder.WITHOUT_FOLDER ? null : folderId
    }`;

  if (page > 1) q.length ? (q += `&page=${page}`) : (q = `?page=${page}`);

  return dAxios.get(`/document-template${q}`).then((res) => ({
    data: res.data.data || [],
    hasNextPage: res.data.hasNextPage,
  }));
}

export function retrieveTemplate(id: string): Promise<DocumentTemplate> {
  return dAxios.get(`/document-template/${id}`).then((res) => res.data.data);
}

export function renderTemplate(id: string, schemaData: any): Promise<string> {
  const payload = {
    schemaData: JSON.parse(JSON.stringify(schemaData)),
  };

  return dAxios
    .post(`/document-template/${id}/render`, payload)
    .then((res) => res.data);
}

export function deleteTemplate(id: string): Promise<void> {
  return dAxios.delete(`/document-template/${id}`).then((res) => res.data);
}

export function createBlankTemplate(
  payload: CreateBlankTemplatePayload
): Promise<DocumentTemplate> {
  return dAxios
    .post('/document-template/create-blank', payload)
    .then((res) => res.data.data);
}

export function moveTemplate(
  templateId: string,
  newFolderId?: string
): Promise<Document> {
  const payload = {} as {
    folder__id?: string;
  };

  if (newFolderId) {
    payload['folder__id'] = newFolderId;
  }

  return dAxios
    .post(`/document-template/${templateId}/move`, payload)
    .then((res) => res.data.data);
}

export function duplicateTemplate(id: string): Promise<DocumentTemplate> {
  return dAxios
    .post(`/document-template/${id}/duplicate`)
    .then((res) => res.data.data);
}

export function renameTemplate(
  id: string,
  name: string
): Promise<DocumentTemplate> {
  return dAxios
    .patch(`/document-template/${id}/rename`, { name })
    .then((res) => res.data.data);
}
