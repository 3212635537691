import { defineStore } from 'pinia';
import {
  listFolders,
  postFolder,
  deleteFolder,
  patchFolder,
} from '@/services/folder.service';
import { Folder } from '@/types/models.types';
import {
  CreateFolderPayload,
  UpdateFolderPayload,
} from '@/types/payloads.types';
import { useDocumentStore } from '@/stores/document.store';

function getFolderKey(isDocumentFolder: boolean) {
  return isDocumentFolder ? 'documentFolders' : 'templateFolders';
}

function getInitializedKey(isDocumentFolder: boolean) {
  return isDocumentFolder
    ? 'documentFoldersInitialized'
    : 'templateFoldersInitialized';
}

export enum SelectedFolder {
  ALL = 'Všetky dokumenty',
  WITHOUT_FOLDER = 'Bez zložky',
  TRASH = 'Kôš',
  CUSTOM = 'Vlastné',
}

export const useFolderStore = defineStore('folder', {
  state: () => ({
    folder: null as null | Folder,
    documentFolders: [] as Folder[],
    templateFolders: [] as Folder[],

    selectedFolder: SelectedFolder.ALL as SelectedFolder,
    documentFoldersInitialized: false,
    templateFoldersInitialized: false,
  }),
  actions: {
    manageItemsInFolder(
      folderId: string,
      itemId: string,
      action: 'add' | 'remove' = 'add'
    ) {
      const folder = [...this.documentFolders, ...this.templateFolders].find(
        (folder) => folder.id === folderId
      );
      if (!folder) {
        return;
      }

      const itemsKey = folder.isDocumentFolder
        ? 'documents'
        : 'documentTemplates';

      if (action === 'add') {
        folder[itemsKey].push({ id: itemId });
      } else {
        folder[itemsKey].splice(
          folder[itemsKey].findIndex((item) => item.id === itemId),
          1
        );
      }
    },

    async fetchFolders(fetchDocumentFolders = true) {
      const foldersKey = getFolderKey(fetchDocumentFolders);
      const initializedKey = getInitializedKey(fetchDocumentFolders);

      this[foldersKey] = [];
      this[foldersKey] = await listFolders(fetchDocumentFolders);
      this[initializedKey] = true;

      return this[foldersKey];
    },
    async createFolder(payload: CreateFolderPayload) {
      const foldersKey = payload.isDocumentFolder
        ? 'documentFolders'
        : 'templateFolders';

      const newFolder = await postFolder(payload);
      this[foldersKey].push(newFolder);
      this.folder = newFolder;
      this.selectedFolder = SelectedFolder.CUSTOM;

      return newFolder;
    },
    updateFolder(folder: Folder, payload: UpdateFolderPayload) {
      const foldersKey = getFolderKey(folder.isDocumentFolder);

      return patchFolder(folder.id, payload).then((updatedFolder) => {
        this[foldersKey] = this[foldersKey].map((_folder) => {
          console.log(_folder, folder, updatedFolder);

          return folder.id === _folder.id ? updatedFolder : _folder;
        });
        this.folder = updatedFolder;

        console.log(this.documentFolders);
      });
    },
    async removeFolder(folder: Folder) {
      //EP zmaže aj dokumenty v zložke
      await deleteFolder(folder.id);

      const foldersKey = getFolderKey(folder.isDocumentFolder);

      if (this.folder?.id === folder.id) {
        this.folder = null;
        this.selectedFolder = SelectedFolder.ALL;
      }

      //Update folder store
      this[foldersKey] = this[foldersKey].filter(
        (_folder) => _folder.id !== folder.id
      );
      //Update document store
      useDocumentStore().removeDocumentsFromStore(
        folder.documents.map((d) => d.id)
      );
    },
  },
});
