import { defineStore } from 'pinia';

export const useLoaderStore = defineStore('loader', {
  state: () => ({
    loaderMsg: '',
    loading: false,
    loadingAnimation: 'loading.json' as 'loading.json' | 'signature.json',
    animationWidth: 600,
  }),
  actions: {
    resetToDefault() {
      this.loaderMsg = '';
      this.loading = false;
      this.loadingAnimation = 'loading.json';
      this.animationWidth = 600;
    },
  },
});
