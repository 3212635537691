import { DocumentTemplate } from '@/types/models.types';
import { defineStore } from 'pinia';
import {
  listTemplates,
  retrieveTemplate,
  deleteTemplate,
  postTemplate,
  createBlankTemplate as _createBlankTemplate,
  duplicateTemplate as _duplicateTemplate,
  renameTemplate as _renameTemplate,
  moveTemplate,
} from '@/services/document_template.service';
import { SelectedFolder, useFolderStore } from '@/stores/folder.store';
import { CreateTemplatePayload } from '@/types/payloads.types';
import { listDocuments } from '@/services/document.service';

export const useDocumentTemplateStore = defineStore('documentTemplate', {
  state: () => ({
    templates: [] as DocumentTemplate[],
    template: null as DocumentTemplate | null,
    templatesInitialized: false,
    hasNextPage: false,
  }),
  actions: {
    async fetchTemplates(
      selectedFolder: SelectedFolder,
      folderId: string | null = null,
      page = 1
    ) {
      if (page > 1) {
        const { data, hasNextPage } = await listTemplates(
          selectedFolder,
          folderId,
          page
        );
        this.hasNextPage = hasNextPage;

        this.templates.push(...data);
        return this.templates;
      }

      const { data, hasNextPage } = await listTemplates(
        selectedFolder,
        folderId,
        page
      );
      this.templates = data;
      this.hasNextPage = hasNextPage;
      this.templatesInitialized = true;

      return this.templates;
    },
    async fetchTemplate(id: string) {
      this.template = await retrieveTemplate(id);
      return this.template;
    },
    async removeTemplate(id: string) {
      if (id === this.template?.id) this.template = null;

      await deleteTemplate(id);
      this.templates = this.templates.filter((template) => template.id !== id);
    },
    async create(payload: CreateTemplatePayload) {
      const template = await postTemplate(payload);
      this.templates.push(template);
    },

    async createBlankTemplate(folderId?: string) {
      const template = await _createBlankTemplate({
        folderId,
      });

      if (folderId) {
        useFolderStore().manageItemsInFolder(folderId, template.id);
      }

      this.templates.push(template);

      return template;
    },
    async moveToFolder(templateId: string, folderId?: string) {
      const template = this.templates.find(
        (template) => template.id === templateId
      );
      if (!template) {
        return;
      }

      const oldFolderId = template.folderId;

      //Ak momentálne pozeráme custom zložku a dokument presúvame do inej zložky, alebo
      //ak momentálne pozeráme dokumenty bez zložky a dokument nadobúda zložku,
      //tak ho odstránime z aktuálneho zoznamu, kt. zobrazujeme
      if (
        useFolderStore().selectedFolder === SelectedFolder.CUSTOM ||
        (useFolderStore().selectedFolder === SelectedFolder.WITHOUT_FOLDER &&
          !oldFolderId)
      ) {
        this.templates = this.templates.filter(
          (template) => template.id !== templateId
        );
      }

      if (oldFolderId) {
        useFolderStore().manageItemsInFolder(oldFolderId, templateId, 'remove');
      }

      if (folderId) {
        useFolderStore().manageItemsInFolder(folderId, templateId);
      }

      template.folderId = folderId || null;
      return moveTemplate(templateId, folderId);
    },
    async duplicateTemplate(temmplateId: string) {
      const template = await _duplicateTemplate(temmplateId);
      this.templates.push(template);
      return template;
    },
    async renameTemplate(templateId: string, name: string) {
      let oldName: string | undefined;

      this.templates = this.templates.map((_template) => {
        if (_template.id === templateId) {
          oldName = _template.name;
          return { ..._template, name };
        }
        return _template;
      });

      return await _renameTemplate(templateId, name).catch((e) => {
        this.templates = this.templates.map((_document) =>
          _document.id === templateId
            ? { ..._document, name: oldName }
            : _document
        ) as DocumentTemplate[];

        return Promise.reject(e);
      });
    },
  },
});
