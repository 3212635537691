<script setup lang="ts">
import Logo from '@/components/ui/Logo.vue';
import { defineEmits, ref, inject, onMounted } from 'vue';
import Emittery from 'emittery';

defineEmits(['logout']);

const emmiter = inject('emitter') as Emittery;
const hamburgerIcon = ref(null as HTMLElement | null);
const navPlaceholder = ref(null as HTMLElement | null);

emmiter.on('toggleSideNav', () => {
  if (!hamburgerIcon.value) return;

  hamburgerIcon.value.classList.toggle('bi-list');
  hamburgerIcon.value.classList.toggle('bi-x');
});

//Slúži na vyplnenie miesta, ktoré zaberá topNav, keďže má position: fixed
onMounted(() => {
  if (!navPlaceholder.value) return;

  const topNav = document.querySelector('.idocs-nav') as HTMLElement;
  const navPlaceholderHeight = topNav.clientHeight;
  navPlaceholder.value.style.minHeight = `${navPlaceholderHeight}px`;
});
</script>

<template>
  <div ref="topNav" class="d-flex p-3 idocs-nav position-fixed w-100">
    <div class="mw-default w-100 mx-auto justify-content-between d-flex">
      <logo></logo>

      <div
        v-if="$route.name === 'Documents' || $route.name === 'Templates'"
        class="hamburger-btn text-secondary"
        @click="emmiter.emit('toggleSideNav')"
      >
        <i ref="hamburgerIcon" class="bi bi-list h1"></i>
      </div>
    </div>
  </div>

  <div ref="navPlaceholder"></div>
</template>

<style lang="scss" scoped>
@import 'src/scss/global.scss';
@import 'bootstrap/scss/variables';

.hamburger-btn {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    display: none;
  }

  cursor: pointer;
}
</style>
