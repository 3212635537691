<script lang="ts" setup>
import DialogWindow from '@/components/ui/DialogWindow.vue';
import FileInput from '@/components/ui/FileInput.vue';
import { inject, ref } from 'vue';
import { useDocumentStore } from '@/stores/document.store';
import { SelectedFolder, useFolderStore } from '@/stores/folder.store';
import { ToastInterface } from 'vue-toastification';
import { useRoute } from 'vue-router';
import { useDocumentTemplateStore } from '@/stores/document_template.store';
import { ConvertTo } from '@/types/payloads.types';
import Emittery from 'emittery';

const toast = inject('toast') as ToastInterface;

const showFileInputModal = ref(false);
const fileBytes = ref<File | null>(null);
const emitter = inject('emitter') as Emittery;
const uploadingDocument = useRoute().name === 'Documents';

const upload = async () => {
  if (!fileBytes.value) return;

  try {
    const payload = {
      file: fileBytes.value,
      folderId:
        useFolderStore().selectedFolder === SelectedFolder.CUSTOM
          ? useFolderStore().folder?.id || undefined
          : undefined,
    };

    uploadingDocument
      ? await useDocumentStore().create(payload)
      : await useDocumentTemplateStore().create(payload);

    toast('Súbor bol úspešne nahraný !');
  } catch (e) {
    toast.error('Nepodarilo sa nahrať súbor !');
    console.error(e);
  } finally {
    fileBytes.value = null;
    showFileInputModal.value = false;
  }
};

const createBlank = async (convertTo: ConvertTo = 'docx') => {
  const folderId =
    useFolderStore().selectedFolder === SelectedFolder.CUSTOM
      ? useFolderStore().folder?.id || undefined
      : undefined;

  try {
    const obj = uploadingDocument
      ? await useDocumentStore().createBlankDocument(convertTo, folderId)
      : await useDocumentTemplateStore().createBlankTemplate();

    await emitter.emit(
      uploadingDocument ? 'renameDocument' : 'renameTemplate',
      obj.id
    );
  } catch (e) {
    toast.error('Nepodarilo sa vytvoriť súbor !');
    console.error(e);
  }
};
</script>

<template>
  <portal to="upload-dialog"
    ><dialog-window
      v-model="showFileInputModal"
      :title="uploadingDocument ? 'Nový dokument' : 'Nová šablóna'"
    >
      <template #content>
        <file-input
          :accept="uploadingDocument ? undefined : '.docx'"
          small-design
          v-model="fileBytes"
        ></file-input>
      </template>

      <template #footer>
        <button class="btn btn-light" @click="showFileInputModal = false">
          Zrušiť
        </button>
        <button @click="upload" :disabled="!fileBytes" class="btn btn-primary">
          Nahrať
        </button>
      </template>
    </dialog-window></portal
  >

  <button class="btn btn-primary w-100" @click="showFileInputModal = true">
    {{ uploadingDocument ? 'Nahrať dokument' : 'Nahrať šablónu' }}
  </button>

  <div v-if="uploadingDocument">
    <button
      class="btn btn-light w-100 mt-3 dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      aria-haspopup="true"
      role="button"
    >
      Nový dokument
    </button>

    <ul class="dropdown-menu dropdown-menu-end">
      <li class="dropdown-item" @click="createBlank('docx')">.docx</li>

      <li class="dropdown-item" @click="createBlank('pptx')">.pptx</li>

      <li class="dropdown-item" @click="createBlank('xlsx')">.xlsx</li>

      <li class="dropdown-item" @click="createBlank('pdf')">.pdf</li>
    </ul>
  </div>

  <button v-else class="btn btn-light w-100 mt-3" @click="createBlank">
    Nová šablóna
  </button>
</template>
