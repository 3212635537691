<script lang="ts" setup>
import { SelectedFolder, useFolderStore } from '@/stores/folder.store';
import { inject, onBeforeUnmount, ref, watch } from 'vue';
import { ToastInterface } from 'vue-toastification';
import { useRoute } from 'vue-router';
import DialogWindow from '@/components/ui/DialogWindow.vue';
import { Folder } from '@/types/models.types';

const toast = inject('toast') as ToastInterface;
//Flags pre pridanie, update a zobrazenie modálu na odstránenie zložky
const addingFolder = ref(false);
const isUpdatingFolder = ref(false);
const showRemoveModal = ref(false);

//Používame na zobrazovanie spinnera pri create, update, či delete
const loading = ref(false);
//Používame na určenie mena novej zložke, alebo nového mena updatovanej zložky
const newFolderName = ref('');
//Tu ukladáme zložku, ktorú chceme odstrániť
let folderToDelete: Folder | null = null;
//Tu ukladáme zložku, ktorú chceme updatovať
let folderToUpdate: Folder | null = null;

//Používame na určenie, či sa jedná o zložku s dokumentami, alebo šablónami
const isDocumentFolder = useRoute().name === 'Documents';
//Na základe isDocumentFolder určujeme, kľúče v store na fieldoch, s kt. pracujeme
const initializedKey = isDocumentFolder
  ? 'documentFoldersInitialized'
  : 'templateFoldersInitialized';
const folderKey = isDocumentFolder ? 'documentFolders' : 'templateFolders';

if (!useFolderStore()[initializedKey]) {
  useFolderStore().fetchFolders(isDocumentFolder);
}

//Vždy, keď sa zmení addingFolder alebo isUpdatingFolder, tak sa nabinduje event listener na klávesy
watch([addingFolder, isUpdatingFolder], () => {
  if (addingFolder.value || isUpdatingFolder.value) {
    document.onkeydown = (e) => {
      if (e.key === 'Enter') {
        if (addingFolder.value) return createFolder();
        if (isUpdatingFolder.value && folderToUpdate)
          updateFolderName(folderToUpdate);
      } else if (e.key === 'Escape') {
        addingFolder.value = false;
        isUpdatingFolder.value = false;
        newFolderName.value = '';
      }
    };
  } else {
    document.onkeydown = null;
  }
});
//pre istotu, aby sme nezabudli odstrániť event listenery
onBeforeUnmount(() => {
  document.onkeydown = null;
});

//Metódy na vytvorenie, update a odstránenie zložky
const createFolder = () => {
  if (!newFolderName.value) return;

  loading.value = true;

  useFolderStore()
    .createFolder({
      name: newFolderName.value,
      isDocumentFolder,
    })
    .catch((e) => {
      if (e.response?.status >= 400 && e.response?.status < 500) {
        //Ak je kod medzi 400 a 500, tak zobrazíme chybovú hlášku z BE
        toast.error(e.response.data.message);
      } else {
        console.error(e);
        toast.error('Nepodarilo sa vytvoriť zložku !');
      }

      return Promise.reject(e);
    })
    .then(() => {
      toast('Zložka bola úspešne vytvorená !');

      addingFolder.value = false;
      newFolderName.value = '';
    })
    .finally(() => {
      loading.value = false;
    });
};

const updateFolderName = (folder: Folder) => {
  loading.value = true;

  useFolderStore()
    .updateFolder(folder, { name: newFolderName.value })
    .catch((e) => {
      console.error(e);
      if (e.response?.status >= 400 && e.response?.status < 500)
        toast.error(e.response.data.message);
      else toast.error('Nepodarilo sa upraviť zložku !');
      return Promise.reject(e);
    })
    .then(() => {
      toast('Zložka bola úspešne upravená !');

      isUpdatingFolder.value = false;
      newFolderName.value = '';
    })
    .finally(() => {
      loading.value = false;
    });
};

const removeFolder = async () => {
  if (!folderToDelete) return;

  loading.value = true;

  await useFolderStore()
    .removeFolder(folderToDelete)
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    })
    .then(() => {
      toast('Zložka bola úspešne odstránená !');
    })
    .finally(() => {
      showRemoveModal.value = false;
      loading.value = false;
    });
};
</script>

<template>
  <portal to="folder-remove-dialog">
    <dialog-window v-model="showRemoveModal" title="Ste si istý ?">
      <template #content>
        <div class="p-3">
          Naozaj chcete odstrániť zložku
          <strong>{{ folderToDelete?.name }}</strong> ? Súbory v nej uložené
          nebude možné obnoviť.
        </div>
      </template>

      <template #footer>
        <button class="btn btn-light" @click="showRemoveModal = false">
          Zrušiť
        </button>
        <button class="btn btn-danger" @click="removeFolder(false)">
          Odstrániť
        </button>
      </template>
    </dialog-window>
  </portal>

  <div class="py-3">
    <div class="d-flex justify-content-between">
      <div class="text-secondary fw-medium">Zobraziť</div>
    </div>
    <div class="mt-3 d-flex gap-default flex-column">
      <div
        :class="{
          active: useFolderStore().selectedFolder === SelectedFolder.ALL,
        }"
        class="folder custom"
        @click="useFolderStore().selectedFolder = SelectedFolder.ALL"
      >
        Všetky {{ isDocumentFolder ? 'dokumenty' : 'šablóny' }}
      </div>
      <div
        :class="{
          active:
            useFolderStore().selectedFolder === SelectedFolder.WITHOUT_FOLDER,
        }"
        class="folder custom"
        @click="useFolderStore().selectedFolder = SelectedFolder.WITHOUT_FOLDER"
      >
        Bez zložky
      </div>
    </div>

    <div class="d-flex justify-content-between mt-3">
      <div class="text-secondary fw-medium">Moje zložky</div>
      <i
        class="bi bi-plus-lg text-primary cursor-pointer"
        @click="addingFolder = true"
      ></i>
    </div>

    <div class="mt-3 d-flex gap-default flex-column">
      <div v-if="addingFolder" class="d-flex">
        <input
          v-model="newFolderName"
          class="form-control-sm form-control border-default border-radius-none no-outline border-right-none"
          placeholder="Názov zložky"
          type="text"
        />

        <button
          :disabled="!newFolderName"
          class="btn btn-light icon-btn btn-small border-radius-none border-default-top border-default-bottom"
          @click="createFolder"
        >
          <i v-if="!loading" class="bi bi-check"></i>
          <span v-else class="spinner-border spinner-border-sm mt-1"></span>
        </button>
        <button
          class="btn btn-light icon-btn btn-small border-radius-none border-default-top border-default-bottom border-default-right"
          @click="
            () => {
              addingFolder = false;
              newFolderName = '';
            }
          "
        >
          <i class="bi bi-x"></i>
        </button>
      </div>

      <div v-for="folder in useFolderStore()[folderKey]" :key="folder.id">
        <div
          v-if="!isUpdatingFolder || folderToUpdate?.id !== folder.id"
          :class="{
            active:
              useFolderStore().folder?.id === folder.id &&
              useFolderStore().selectedFolder === SelectedFolder.CUSTOM,
          }"
          class="folder custom"
          @click="
            (e) => {
              if (e.target.classList.contains('overriden-action')) return;

              useFolderStore().selectedFolder = SelectedFolder.CUSTOM;
              useFolderStore().folder = folder;
            }
          "
        >
          <div>{{ folder.name }}</div>

          <div class="d-flex gap-small">
            <i
              class="bi bi-pencil-fill overriden-action"
              @click="
                () => {
                  newFolderName = folder.name;
                  isUpdatingFolder = true;
                  folderToUpdate = folder;
                }
              "
            ></i>
            <i
              class="bi bi-trash3-fill overriden-action"
              @click="
                () => {
                  folderToDelete = folder;
                  showRemoveModal = true;
                }
              "
            ></i>
          </div>
        </div>

        <div v-else class="d-flex">
          <input
            v-model="newFolderName"
            class="form-control-sm form-control border-default border-radius-none no-outline"
            placeholder="Názov zložky"
            type="text"
          />

          <button
            :disabled="!newFolderName"
            class="btn btn-light icon-btn btn-small border-radius-none border-default-top border-default-bottom"
            @click="updateFolderName(folder)"
          >
            <i v-if="!loading" class="bi bi-check"></i>
            <span v-else class="spinner-border spinner-border-sm mt-1"></span>
          </button>
          <button
            class="btn btn-light icon-btn btn-small border-radius-none border-default-top border-default-bottom border-default-right"
            @click="
              () => {
                isUpdatingFolder = false;
                newFolderName = '';
              }
            "
          >
            <i class="bi bi-x"></i>
          </button>
        </div>
      </div>

      <div v-if="!useFolderStore()[folderKey]?.length" class="text-muted">
        Nemáte ešte žiadnu zložku
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../scss/global.scss';

.folder {
  transition: all $transition-length ease-in-out;
  font-weight: 500;

  &.custom {
    $custom-color: $primary;
    $custom-color--hovered: $primary--hovered;
    $custom-color--active: $primary;

    color: $secondary;
    background-color: $light;

    &:hover {
      color: $secondary--hovered;
      background-color: rgba($secondary--hovered, 0.1);
    }

    &.active {
      color: $custom-color--active;
      background-color: rgba($custom-color--active, 0.1);
      border: 1px solid $custom-color--active;
    }

    @extend .d-flex, .justify-content-between;
  }

  &.active {
    @extend .cursor-default;
  }

  @extend .radius-default, .p-2, .small, .cursor-pointer;
}
</style>
